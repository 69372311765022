// @import
//   "minima/skins/classic",
//   "minima/initialize";

// @import "bootstrap-5.2.3/scss/bootstrap.scss"
@import "bootstrap-5.2.3/dist/css/bootstrap";

// overlay image title on hover
.day {
    article {
        figure {
            position: relative;

            &:hover {
                figcaption {
                    opacity: 1;
                }
            }

            a {
                position: relatve;
            }

            figcaption {
                display: block;
                position: absolute; 
                bottom: 0; 
                background: rgb(0, 0, 0);
                background: rgba(0, 0, 0, 0.5); /* Black see-through */
                color: white; 
                width: 100%;
                transition: .3s ease;
                opacity: 0;
                padding: 1.25em 1em 1.25em 1em;
                text-align: center;
            }

        }
    }
}